import React from "react";
import Member from "./helper/member";
import { Link } from "react-router-dom";
import image1 from "../assets/what we do.jpg";
import image2 from "../assets/health.jpeg";
import image3 from "../assets/unemployment.jpeg";
import image4 from "../assets/sdgs81.jpg";
// import Comunication from './helper/comunication'
import Photos from "./photo";
// import Feeds from "./feeds";
// import Sponsors from "./helper/sponsors";

function Landing() {
  return (
    <div>
      {/* <Comunication/> */}
      <div id="first-slider">
        <div
          id="carousel-example-generic"
          className="carousel slide carousel-fade"
        >
          <ol className="carousel-indicators">
            <li
              data-target="#carousel-example-generic"
              data-slide-to="0"
              className="active"
            ></li>
            <li data-target="#carousel-example-generic" data-slide-to="1"></li>
            <li data-target="#carousel-example-generic" data-slide-to="2"></li>
            <li data-target="#carousel-example-generic" data-slide-to="3"></li>
          </ol>
          {/* Wrapper for slides  */}
          <div className="carousel-inner" role="listbox">
            {/* Item 1  */}
            <div className="item active slide1">
              <h2 data-animation="animated bounceInDown">Be A Voice</h2>
              <h3 data-animation="animated bounceInDown">
                A youth led initiative to advocate for the most vulnerable
                members of our community (women and children) to improve their
                livelihood through education, capacity building and health.
              </h3>
              <h4 data-animation="animated bounceInUp">
                <Link to="/about">READ MORE</Link>
              </h4>
            </div>
            {/* Item 2  */}
            <div className="item slide2">
              <h2 data-animation="animated bounceInDown">
                <span>Be A Voice</span>
              </h2>
              <h3 data-animation="animated bounceInDown">
                give them its commitments through protecting the value of
                Humanity and advocating for their rights to health treatment and
                family.
              </h3>
              <h4 data-animation="animated bounceInUp">
                <Link to="/about">READ MORE</Link>
              </h4>
            </div>
            {/* Item 3  */}
            <div className="item slide3">
              <h2 data-animation="animated bounceInDown">
                <span>Be A Voice</span>
              </h2>
              <h3 data-animation="animated bounceInDown">
                We believe that empowering women by enhancing their capacity to
                manage economic and social risks
              </h3>
              <h4 data-animation="animated bounceInUp">
                <Link to="/about">READ MORE</Link>
              </h4>
            </div>
            {/* < Item 4  */}
            <div className="item slide4">
              <h2 data-animation="animated bounceInDown">
                <span>Be A Voice</span>
              </h2>
              <h3 data-animation="animated bounceInDown">
                we want to live and experience the significance of humanity, we
                are hoping to give our commitments through protecting the value
                of Humanity and advocating for their rights to education, health
                and family.
              </h3>
              <h4 data-animation="animated bounceInUp">
                <Link to="/about">READ MORE</Link>
              </h4>
            </div>
            {/* <!-- End Item 4 --> */}
          </div>
          {/* <!-- End Wrapper for slides--> */}
          <Link
            className="left carousel-control"
            to="#"
            role="button"
            data-slide="prev"
          >
            <i className="fa fa-angle-left"></i>
            <span className="sr-only">Previous</span>
          </Link>
          <Link
            className="right carousel-control"
            to="#"
            role="button"
            data-slide="next"
          >
            <i className="fa fa-angle-right"></i>
            <span className="sr-only">Next</span>
          </Link>
        </div>
      </div>
      <section id="about-sec">
        <div className="container">
          <div className="row text-center">
            <h1>ABOUT BeVoice</h1>
            <hr />
            <h5>
              Bevoice is non-Government non-profit organization founded by A
              Humanitarian activist in 2019 to support the community
              experiencing extremely poverty,
            </h5>
            <p>
              A community based Youth Humanitarians Contribution to support
              individuals in extremely poverty limited access to Education and
              Health and Capacity Building through Educating Value of Humanity
              to the SDGs as a contribution of Youths to Eradicate Poverty
            </p>
          </div>
        </div>
      </section>
      <section id="activities-sec">
        <div className="activities-sec-wrapper">
          <div className="row text-center">
            <h1>WHAT WE DO?</h1>
            <hr />
            <h5>
              Bevoice works in Three pillars of social development which are
              Education, Women Empowerment and Health.
            </h5>
            <div className="text-left">
              <div className="col-md-3 clearfix top-off">
                <div className="grid-content-left">
                  <i className="fa fa-heart"></i>
                </div>
                <div className="content-wrapper">
                  <h4>Education</h4>
                  <p>
                    We believe that for a Better Sustainable Development, Quality Education
                    should be at the forefront, because every child has the right to
                    quality Education regardless of their lower living standard, so that they can journey towards
                    their dreams with Confidence and Dignity.
                  </p>
                  <Link to="/" title="">
                    Read More
                  </Link>
                </div>
              </div>

              <div className="col-md-3 clearfix top-off">
                <div className="grid-content-left">
                  <i className="fa fa-tint"></i>
                </div>
                <div className="content-wrapper">
                  <h4>Health</h4>
                  <p>
                    We, the Youth Humanitarians, recognize that Health is a
                    Fundamental right for all people.For those living
                    in several poverty, it might be difficult to afford Health
                    services such as medical coverage, treatment coverage, good
                    health services. We believe that Youth Contribution to universal
                    health coverage will help to accomplish the SDGs 3 to the most vurnerable
                    extremely poor individuals in hospitals with financial difficulty from 
                    costly out-of-pocket charges.
                  </p>
                  <Link to="/" title="">
                    Read More
                  </Link>
                </div>
              </div>
              <div className="col-md-3 clearfix top-off">
                <div className="grid-content-left ">
                  <i className="fa fa-home"></i>
                </div>
                <div className="content-wrapper">
                  <h4>CAPACITY BUILDING</h4>
                  <p>
                    "If you educate a woman, you educate a family, if you
                    educate a girl, you educate the future" - Queen Rania of
                    Jordan Women are the backbones of the family and the nation as
                    whole. Capacity building for women and girls through
                    education (soft and technical skills) and understanding of
                    crucial issues affecting them equips and allows them to
                    make life-changing decisions for the most pressing
                    difficulties confronting communities, resulting in a more fair
                    and equitable society. 
                    empowering women by improving their ability to manage
                    economic and social risks would shape them into entrepreneurs
                    able to generate jobs and boosting their living conditions.
                  </p>
                  <Link to="/" title="">
                    Read More
                  </Link>
                </div>
              </div>
              <div className="col-md-3 clearfix top-off">
                <div className="grid-content-left">
                  <i className="fa fa-tint"></i>
                </div>
                <div className="content-wrapper">
                  <h4>Values of Humanity</h4>
                  <p>
                    As Youth Humanitarians, we believe that Human values are the
                    basis and virtues that guide us to consider the human factor 
                    while interacting with other humans,
                    where our values inform our ideas, words, and deeds. So
                    we included educating values of humanity in our mission
                    because values help us to grow and develop so that we can create
                    the future we want to experience. when moral values are
                    instilled in children’s thoughts, feelings and actions, they
                    function as ideals and standards that govern their actions
                    in their life, for human participation is
                    seen in behaviour and work, so educating values of
                    Humanity will result in the prevention of the abuse of
                    activities.
                  </p>
                  <Link to="/" title="">
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="video-sec">
        <div className="container">
          <div className="row text-center">
            <h1>How can you help?</h1>
            <hr />
            <h5>
              They are more than hundreds in the same sector and much more in
              our country who are struggling the consequences of what their
              parents did like of abandoning them and the extremely poverty they
              never chose to get birth in
            </h5>
            <div className="text-left">
              <div className="col-md-6 clearfix top-off">
                <video width="400" controls>
                  <source src="../assets/aaaaa.mp4" type="video/mp4" />
                </video>
              </div>
              <div className="col-md-6 clearfix top-off">
                <div className="media  media-member help">
                  <div className="media-image">
                    <img
                      src="images/g2.png"
                      className="attachment-full size-full"
                      alt="g1"
                    />{" "}
                  </div>
                  <div className="how-can">
                    <h5>BECOME A MEMBER</h5>
                    <p>
                      Bevoice don’t wish to see the children suffer and limited
                      to their dreams for they weren’t chosen that. to be apart
                      of us think that again
                    </p>
                  </div>
                </div>
                <div className="media media-member help">
                  <div className="media-image">
                    <img
                      src="images/g1.png"
                      className="attachment-full size-full"
                      alt="g1"
                    />{" "}
                  </div>
                  <div className="how-can">
                    <h5>MAKE A GIFT</h5>
                    <p>
                      we have tried to put 20% of them back to school,fell free
                      to contribute to{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="projects-sec">
        <div className="container">
          <div className="row text-center">
            <h1>OUR LATEST PROJECTS</h1>
            <hr />
            <h5>
              Bevoice works in Three pillars of social development which are
              Education, Women Empowerment and Health.
            </h5>
            <div className="text-center">
              <div className="col-md-4 clearfix top-off">
                <div className="grid-image home-img">
                  <img src={image1} alt="" />
                </div>
                <div className="post-content">
                  <h3>Gahanga: Education for Children</h3>
                  <hr />
                  <p>
                    we Youth Humanitarians in SDG4 Believes that Quality
                    Education is the Child Right within Equity Gender, Right now
                    as of 15 youth’s contributions, we support 20% of the
                    Children we have with limited access to Education, 80% of
                    them are still On the Pending List. where now 60% Children
                    in Education In Our Program are Girls.
                  </p>
                  <Link to="/projects" title="">
                    View Project
                  </Link>
                </div>
              </div>
              <div className="col-md-4 clearfix top-off">
                <div className="grid-image home-img">
                  <img src={image2} alt="" />
                </div>
                <div className="post-content">
                  <h3>Kicukiro: Womengirls empowement</h3>
                  <hr />
                  <p>
                    We Youth Humanitarian in SDGs8, believe that raising the
                    status of the Women through Education, literacy and
                    trainings is the Women Empowerment leading them to financial
                    access to sustainable economic growth
                  </p>
                  <Link to="/projects" title="">
                    View Project
                  </Link>
                </div>
              </div>
              <div className="col-md-4 clearfix top-off">
                <div className="grid-image home-img">
                  <img src={image3} alt="" />
                </div>
                <div className="post-content">
                  <h3>CHUK HOSPITAL: UNIVERSAL HEALTH COVERAGE(UHC)</h3>
                  <hr />
                  <p>
                    We Youth Humanitarians in Health through Inezaforall group,
                    we are collaborating with Social worker in Hospitals
                    starting from CHUK to tackle financial hardships to health
                    Services
                  </p>
                  <Link to="/projects" title="">
                    View Project
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="article">
      <h1>LATEST ARTICLE</h1>
            <hr />
            <div className="act-box clearfix">
              <div className="col-md-6">
                <div className="image art">
                  <img src={image4} alt="" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="act-pad">
                  <h4>Empowering Women: Showcasing Success at Kicukiro District's Open Day</h4>
                  <p className="text-left">
                  At the event, we had the opportunity to Show the Work of 30 
                  trained Women who had no job&basic Education  who are now  
                  working in the working space we provided Allison Hickey 
                  Tailoring Workshop{" "}
                  </p>
                  <div className="price">
                    <Link to="/article" className="btn1">
                      see more
                    </Link>
                  </div>

                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
      </section>

      {/* <Sponsors /> */}

      <section id="gallery-sec">
        <div className="container">
          <div className="row text-center">
            <h1>OUR GALLERY</h1>
            <hr />
            <ul className="clearfix">
              {Photos.slice(0, 8).map((el, index) => {
                return (
                  <li>
                    <Link to="#" className="swipebox" title="My Caption">
                      <div className="image d-image">
                        <img src={el.image} alt="" />
                        <div className="overlay">
                          <i className="fa fa-search-plus"></i>
                        </div>
                      </div>
                    </Link>
                  </li>
                );
              })}
            </ul>
            <div className="text-center">
              <Link to="/photo" className="btn1">
                View More
              </Link>
            </div>
          </div>
        </div>
      </section>
      <Member />
    </div>
  );
}

export default Landing;
