import image1 from '../assets/1.jpg'
import image2 from '../assets/2.JPG'
import image3 from '../assets/3.JPG'
import image4 from '../assets/what we do 2.jpg'
import image5 from '../assets/what we do.jpg'
import image6 from '../assets/what we do 3.jpg'

const Photos = [
    {
        image:image1
        
    },
    {
        image:image2
    },
    {
        image:image3
    },
    {
        image:image4
    },
    {
        image:image5
    },
    {
        image:image6
    },
    {
        image:image1
    },
    {
        image:image2
    },
    {
        image:image3
    },
    {
        image:image4
    },
    {
        image:image5
    },
    {
        image:image6
    },
    {
        image:image1
    },
    {
        image:image2
    },
    {
        image:image2
    },
    {
        image:image2
    },
]

export default Photos;