import React from "react";
import { Link } from "react-router-dom";
// import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
// import logo from "../assets/logo.png";

function Header() {
  // const config = {
  //   public_key: "FLWPUBK-**************************-X",
  //   tx_ref: Date.now(),
  //   amount: 100,
  //   currency: "USD",
  //   payment_options: "card,mobilemoney,ussd",
  //   customer: {
  //     email: "user@gmail.com",
  //     phone_number: "0781964133",
  //     name: "Freddy",
  //   },
  //   customizations: {
  //     title: "my Payment Title",
  //     description: "Payment for items in cart",
  //     logo: { logo },
  //   },
  // };

  // const handleFlutterPayment = useFlutterwave(config);
  return (
    <div>
      <div className="topbar">
        <div className="container">
          <div className="row">
            <div className="bar-phone">
              <i className="fa fa-phone"></i> <span>Call Us :</span>{" "}
              <strong>+25 0725 555 539</strong>
            </div>
            <div className="bar-mail">
              <i className="fa fa-envelope"></i> <span>Mail Us :</span>{" "}
              <strong>info@bevoice.org</strong>
            </div>
            <div className="header-social">
              <a
                className="facebook"
                href="https://www.facebook.com/BeVoice-111105420718322"
                title="facebook"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa fa-facebook"></i>{" "}
              </a>
              <a
                className="twitter"
                href="https://twitter.com/Bevoiceorg"
                title="twitter"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa fa-twitter"></i>{" "}
              </a>
              <a
                className="linkedin"
                href="https://www.linkedin.com/company/bevoice-organization"
                title="linkedin"
                target="_blank"
                rel="nofollow noreferrer"
              >
                <i className="fa fa-linkedin"></i>{" "}
              </a>
              <Link
                className="google"
                to="#"
                title="google-plus"
                target="_blank"
                rel="nofollow"
              >
                <i className="fa fa-google-plus"></i>{" "}
              </Link>
              <Link
                className="youtube"
                to="#"
                title="youtube-play"
                target="_blank"
                rel="nofollow"
              >
                <i className="fa fa-youtube-play"></i>{" "}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <nav className="navbar navbar-default navbar-sticky bootsnav">
        <div className="container">
          <div className="row">
            <div className="attr-nav">
              <Link className="sponsor-button" to="#">
                Join Us
              </Link>
              <button
                className="donation"
                // onClick={() => {
                //   handleFlutterPayment({
                //     callback: (response) => {
                //       console.log(response);
                //       closePaymentModal(); // this will close the modal programmatically
                //     },
                //     onClose: () => {},
                //   });
                // }}
              >
                donate now
              </button>
            </div>
            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggle"
                data-toggle="collapse"
                data-target="#navbar-menu"
              >
                <i className="fa fa-bars"></i>
              </button>
              <Link className="navbar-brand logo" to="/">
                <img
                  src="images/assets/logo.png"
                  className="img-responsive"
                  alt=""
                />
              </Link>
            </div>
            <div className="collapse navbar-collapse" id="navbar-menu">
              <ul
                className="nav navbar-nav navbar-right"
                data-in="fadeInDown"
                data-out="fadeOutUp"
              >
                <li>
                  <Link to="/" className="header-link">
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/about" className="header-link">
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to="/activity" className="header-link">
                    Activities
                  </Link>
                </li>
                <li>
                  <Link to="/projects" id="project" className="header-link">
                    Projects
                  </Link>
                  {/* <ul className='sublink'><li><Link to="#">education</Link></li><li><Link to="#">WOMENGIRLS EMPOWEMENT</Link></li><li><Link to="#">UNIVERSAL HEALTH COVERAGE(UHC)</Link></li><li><Link to="#">EDUCATIVE VALUE OF HUMANITY</Link></li></ul> */}
                </li>

                <li>
                  <Link to="/photo" className="header-link">
                    Gallery
                  </Link>
                </li>
                <li className="header-link">
                  <Link to="/contact">Contact Us</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Header;
