import React from "react";
import image1 from "../assets/sdgs81.jpg";
import image2 from "../assets/sdgs82.jpg";
import image3 from "../assets/sdgs83.jpg";
import image5 from "../assets/RwandaLabour.jfif";
// import image6 from "../assets/educating.jpg";
import { Link } from "react-router-dom";
import Member from "./helper/member";

function Activity() {
  return (
    <div>
      <section id="inner-banner">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-sm-6">
                <h1>Activity</h1>
              </div>
              <div className="col-sm-6">
                <h6 className="breadcrumb">
                  <Link to="/">Home</Link> / Activity
                </h6>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="about-sec">
        <div className="container">
          <div className="row text-left">
          <div className="act-box ">
              <div className="col-md-8">
                <div className="image b5">
                  <img src={image1} alt="" />
                </div>
                <h4>Empowering Women: Showcasing Success at Kicukiro District's Open Day</h4>

                <p>We're excited to share that this Friday, we took part in the Kicukiro District “Open Day.”</p>

                <p>At the event, we had the opportunity to showcase the remarkable work of 
                  30 trained women who previously had no job and basic education. 
                  These women are now thriving in the working space we provided through 
                  the Allison Hickey Tailoring Workshop. It was truly inspiring to see 
                  the skills and dedication they have developed, transforming their lives 
                  and contributing positively to our community.</p>
                  <div className="image b5">
                  <img src={image2} alt="" />
                </div>

                  <p>In addition to this, we were honored to be recognized for our consistent 
                    efforts in enhancing the well-being of our community. Our commitment to 
                    promoting Sustainable Development Goal 8, "Decent Work and Economic Growth,
                    " was highlighted. Through our initiatives, we have created employment 
                    opportunities for individuals who were previously extremely poor, thereby
                    driving economic growth and fostering a sense of purpose and empowerment.</p>
                    <div className="image b5">
                  <img src={image3} alt="" />
                </div>
                  
                  <p>We are proud of the progress we've made and remain dedicated to making a 
                    significant impact in the lives of those in our community.</p>
              </div>
              <div className="col-md-4">
                <div className="act-pad">
                  <h4>LATEST NEWS</h4>
                  <Link to="https://www.linkedin.com/posts/bevoice-organization_were-excited-to-share-that-the-previous-activity-7209642874957660165-Xcv7?utm_source=share&utm_medium=member_desktop" target="_blank">
                  <div className="image b5">
                    <img src={image5} alt="" />
                  </div>
                  <h6>Collaborating for Change: Insights from the Consultative Forum on Job Creation and Decent Work in Kigali</h6>
                  </Link>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Member />
    </div>
  );
}

export default Activity;
