import React from "react";
import image1 from "../../assets/what we do.jpg";
import "./about.scss";
import HistoryJs from "./history-js";
import arsene from "../../assets/arsen.jpg";

function History() {
  return (
    <div>
      <div className="container">
        <div className="history-cont">
          <div className="history-image">
            <img src={image1} alt="" />
          </div>
          <div className="intro-history">
            <p>
              {" "}
              Bevoice is a non-Government non-profit organization founded by A
              Humanitarian activist in 2019 to support the community
              experiencing extreme poverty, and limited access to education and
              health.
            </p>
            <p>
              {" "}
              In Bevoice we are passionate and committed to be a voice and
              advocate for those people through different supporters who believe
              in supporting the vulnerable communities.
            </p>
            <div className="btn-about">
              <button className="donation">Read More</button>
            </div>
          </div>
        </div>
        <div className="leaderInfo">
          <div className="">
            <div>
              <img src={arsene} alt="" className="leaderImg" />
            </div>
            <div>
              <strong>Arsene Ishimwe</strong>
            </div>
          </div>
  
              <HistoryJs limit={3800}>
            
              This commitment started when I, Arsene Ishimwe was 15 years old.
              On my young age I met A boy who spent his whole entire life in the
              orphanage due to the genocide against Tutsi 1994 in Rwanda, this
              young boy never knew nor a mother or a father which made his life
              so miserable, he called himself a human with no identity.
              
            
              Due to orphanage life, he was adopted by a family which he was
              exciting to join and expect to be treated as a kid at home,
              unfortunately the adopting family put him into home labor like he
              wasn’t deserving the future as everyone else, fights among them
              deteriorated and diminished his hope.
            
              Luckily he met a kind school director who was touched by his
              story, she signed him in primary, he was very clever that made him
              to pass national examination and the good mother searched for him
              some sponsorship, his dreams were in steps but unluckily by his
              third year to shift in the advanced level the sponsorship stopped.
              His hope started slowly by slowly to diminish until he found
              himself as a street rat with no value and home.
            
              Even if he didn’t know his family he was strongly eager to attend
              school as the only hope that can change his life, he had a hope of
              becoming a father so that he will feel comfortable on having a
              family around him.
            
              His faith became faithless that forwarded him to commit suicide
              but luckily didn’t die so to restrict himself from thinking about
              himself, he used drugs. I met him over and asked him the reason
              why he was doing such unhealthy thing?
            
              He replied with sadness that he hadn’t reason to live because he
              was lonely with no family and home so nothing to lose. I asked him
              again, what do you wish? He replied, if I had a family, I would
              not have lived like this, I want to be treated well but nobody is
              caring.
            
              From that day on, I started to ignite his hope by preventing him
              from drugs taking, I decided to take care of him as his father, I
              was 18years old that time. That journey took more than 3years,
              caring him returned his value for rights he didn’t have, now today
              the orphaned boy formed a group of youth with the same story to
              protect others from drugs using. His story pushed me to write a
              book called ‘the dream to solve true thunder storming life-based
              story book.
            
              in 2018, two kids came to our founder who were in age of 10 and 12
              searching for advocacy, they were informed that he was one of the
              youth of ineza for all group which advocates and strengthens the
              individuals in elderly or experiencing sickness in hospitals,
              these children were effected by HIV/AIDS.
            
              Among those kids One was a girl, her mother got drunk and the
              child got rapped at the age of 9 and another one was a boy who had
              born with HIV/AIDS from his mother, his mother died because of
              HIV/AIDS and left him with his numb granny
            
              Lack of someone to speak up for them early made their case known
              at their deadline, we lost both of them for the cause of lacking
              advocacy. Extremely poverty made them voiceless, until the baby
              angels decided to run into streets searching one selfless person
              who can understand their pain, but was too late that time. they
              are resting in peace in the almighty hands.
            
              Later he went at their village and found hundreds of children
              there who don’t have access to education at the ages of 7-15
              because of their parents are experiencing extreme poverty and
              incapable of putting food on the table, finding school
              requirements and healthy insurance at the same time and also
              paying a monthly rent of only 5$ which sometimes become so hard to
              find.
            
              In 2019, Came a little six years old princess with brittle bone
              disease from Gisagara district southern province of Rwanda from 2
              years of struggling to be heard, her parents were desperate about
              her treatment which were resumed for 1year and 6months for the
              lack of support.
            
              Within the same year, more than 10children with the same
              disability problem came where now two of them died, Life for them
              got more expensive and Harder during the Pandemic irregular
              treatments led them to death. There are unknown Innocent children
              dying because of their parents are extremely poor and
              unrecognized.
            
              Bevoice wants to break that gap so that it will bridge them with
              the supporters and other organization envisioned to protect their
              rights before being too late.
            
              We are hoping that for the Beauty of Humanity every Human being
              born with Love in his/ her heart through giving A value we Can
              ignite their Hope to the future of our country’s development, we
              believe that God the doctor of all doctors will keep working
              through us to strengthen the beauty of life with those with
              disabilities….
            
            the main reasons Bevoice came in place:
            
              they are people out there who are speaking in the Name of Humanity
              for their own benefits, which is an abuse of Humanity decreased
              the Trust of activities warding Supporting the needy, Bevoice came
              Stop this abuse.
            
              There are many children in our country and also globally who are
              facing the consequences of what their parents did or the extremely
              poverty they never chose to get birth in
            
              Bevoice don’t want to see the children suffer and limited to their
              dreams because of someone else’s wrongdoings
            
              they are many children who are experiencing the hardest life not
              because of their choice. Bevoice don’t want young children die for
              the lack of health support, we want them to live and experience
              the significance of humanity.
            
              bevoice want to give them its commitments through protecting the
              value of Humanity and advocating for their rights to education,
              health and family.
              Bevoice don’t want their value to be measured by their living
              standard.
            
            </HistoryJs>
          </div>
        
      </div>
    </div>
  );
}

export default History;
