import { useState } from 'react'


const HistoryJs = ({ limit, children }) => {
    const [isReadMoreShown, setReadMoreShown] = useState(false);
    const toggleBtn = () =>{
        setReadMoreShown(prevState => !prevState)
    }
  return (
        <div id="history-paragraph">
          {isReadMoreShown ? children : children.substr(0, limit)}
          <br/>
          <button type="button" className="show-more donation" onClick={toggleBtn}>{isReadMoreShown ? 'Read Less' : '...Show more'} </button>
        </div>
  )
}

export default HistoryJs