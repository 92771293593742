import arsen from '../assets/arsen.jpg'
import kent from '../assets/members/muhire kent photo.jpg'
import laurent from '../assets/members/Laurent photo.JPG'
import marium from '../assets/members/marium photo.jpg'
import pamela from '../assets/members/IMG-20210820-WA0012.jpg'
import augustin from '../assets/members/bizimana Augustin.png'
import robert from '../assets/members/iradukunda robert.jpg'
import grace from '../assets/members/grace.png'
// import anderson from '../assets/members/anderson.JPG'
import sonia from '../assets/members/sonia.PNG'
import kevin from '../assets/members/bizimana kevin.jpeg'
// import nam from '../assets/members/WhatsApp Image 2021-08-17 at 13.17.20.jpeg'
import grace2 from '../assets/members/grace2.jpeg'
import murisa from '../assets/members/murisa.jpeg'
import milliel from '../assets/members/sonia.jpeg'
// import person from '../assets/Person.jpeg'
import freddy from '../assets/members/IMG-4244.jpg'
import joshua from '../assets/members/joshua.jpg'
import diane from '../assets/members/grad-speach.jpeg'
// import elie from '../assets/members/elie.JPG'
import elia from '../assets/members/elia.jpeg'


const Members = [
    {
        imageName:"Ishimwe Arsene",
        position:"CEO&FOUNDER",
        bio:"identification My name is Ndikumana Elia I am a Humanitarian activist , author and Humanitarian Motivator, I’m a citizen of Rwanda, 25-year-old. I am a writer ( Songs and Movies) I speak fluently our mother tongue (Kinyarwanda), English and, less, French. I am deputy representative of Bevoice organization and a founder of Eflix. Since 2019, Bevoice has provided education to vurnerable children, health treatment and insurance and also Trainings to the capacity building  to the individuals experiencing poverty and chidren with disability.. 2. My Personality, Values and Hobbies I am a quiet, shy and humble person. In my spare time, I love to pray, To sing and play music instruments such as guitar and piano, Love cooking and I sometimes watch movies kind of documentary movies, children movies and motivation speeches. I take pleasure in the simple things in life. My calm and patient personality help me live and work with a diversity of people. I believe in Holy Spirit.3.My daily principals Love Simplicity and",
        image:arsen
    },
    {
        imageName:"Ndikumana Elia",
        position:"COO &Value of Humanity Program officer",
        bio:"identification My name is Ndikumana Elia I am a Humanitarian activist , author and Humanitarian Motivator, I’m a citizen of Rwanda, 25-year-old. I am a writer ( Songs and Movies) I speak fluently our mother tongue (Kinyarwanda), English and, less, French. I am deputy representative of Bevoice organization and a founder of Eflix. Since 2019, Bevoice has provided education to vurnerable children, health treatment and insurance and also Trainings to the capacity building  to the individuals experiencing poverty and chidren with disability.. 2. My Personality, Values and Hobbies I am a quiet, shy and humble person. In my spare time, I love to pray, To sing and play music instruments such as guitar and piano, Love cooking and I sometimes watch movies kind of documentary movies, children movies and motivation speeches. I take pleasure in the simple things in life. My calm and patient personality help me live and work with a diversity of people. I believe in Holy Spirit.3.My daily principals Love Simplicity and",
        image:elia
    },
    {
        imageName:"UWIHOREYE Grace",
        position:"Secretary& FundCompaign Officer",
        bio:"UWIHOREYE Grace is a General Secretary of BEVOICE Non-Governmental Organization.she specialized her studies in Business and Information Communication Technology, she regularly volunteer in Film making company as a communication director and a writer. according to what BEVOICE is and what it does is part of her dream not as a general secretary but as a of BEVOICE member.dedicated to the vulnerable families and  passionate about helping people who needs help indeed, at her young age she grew up seeing her mother helping vulnerable families, her mother inspired her a lot by seeing her doing different actions full of humanity by that she made a decision of doing more than what her mother did.including her personal values is that she always put God first, empathy, achievements and loyalty.and  she is a poet and language translator.She got a certificate in Rwanda as a Tech Women and another certificate From Coursera in Budgeting and Scheduling Projects Hobby: Basket Ball player, reading  and music.",
        image:grace2
    },
    {
        imageName:"Muhire Kent",
        position:"UHC Program Officer",
        bio:"I am Muhire Innocent Kent ,I am the UHC Program officer& the President of Inezaforall Group I was born in Tanzania where my parents were refugees, late i was raised in eastern Rwanda, studied science in high school, and a graduate civil engineer in college, works as entrepreneur, Managing director of VLM ltd. i have eight-year experience of working in civil engineering, I am social worker who likes helping others. i have been working with Ineza for all group for 14 years, Five years as a president leading more than 250 youth volunteer at different hospitals such as CHUK And other children centers like of INSHUTI ZABAKENE CENTRE IRAMIRO In Busanza.",
        image:kent
    },
    {
        imageName:"Kamuhabwa Mariam",
        position:"UHC Disability Activist & Womengirls Empowerment Officer",
        bio:"Miss Kamuhabwa Mariam holds Certificate of Education attained 2016.She served as marketing and sales officer at Yuniriva Company Ltd in 2013 for before joining Frang Company Ltd in 2018 where she worked as a coordinator in charge of human resources.She also served as a coordinator at Project Mureke Dusome loacted at Remera district and assisted Children with disabilities by teaching them how to read and write in conjusartnership Save the Children, Uwezo and USAID for a period of one year (2020).Miss Kamuhabwa Miriam also worked as Customer care agent at Premier Bet Company Ltd, Kigali.",
        image:marium
    },
    {
        imageName:"MUNYEMANA Laurent",
        position:"M&E and Social welfare Officer",
        bio:"Mr. Laurent MUNYEMANA holds a Bachelor’s Degree in Rural Development and Agribusiness and currently he is a sales manager at H2o venture partners/RWANDA-Sigasira Health Ltd.Also got expertise in Safety management, M&E and Data management. Mr. Laurent has been a community friend since his childhood. He has an adamant and loveable personality. He is always willing to help whenever he can and has made his different volunteering groups achieve their goals from numerous community service projects. Among his hobbies, he likes to instruct in seminars, travel, drive many categories of vehicles, playing/watching football, Rugby, cycling, hiking with friends.",
        image:laurent
    },
    {
        imageName:"Umutoniwase Pamela",
        position:"UHC Specialist& President of Auditing committee",
        bio:"My name is Pamella Umutoniwase ; a very quiet,calm,ambitious half Burundian half Rwandan girl and a fifth year Medical student in  Qiqihar Medical university (QMU) china , being a Doctor has been one of my dreams since my very young age and i’ve come to love medicine more now that i know more about it because every patient you meet is a chance to share love, give hope and use your knowledge to make someone’s health better and as a Christian i believe that giving is better than receiving , i have many hobbies but my number one hobby is cooking and yes i enjoy it much.",
        image:pamela
    },
    {
        imageName:"Bizimana Kevin",
        position:"Field Officer &Auditing Committee",
        bio:"My name is Bizimana kevin; a very quiet, calm, half Rwandan and half Burundian boy and just finished his studies in IPRC Kigali, being an engineer is one of my dream since I was a little child, through my experience in civil engineering i’ve come to learn that being humble and kind to each person give value to your life and giving people what they want and I believe that giving is better than receiving, I have many hobbies but one of them is basketball.",
        image:kevin
    },
    {
        imageName:"MURAGIJIMANA Chimene ",
        position:"Corporate Communication Officer",
        image:sonia
    },
    {
        imageName:"Joshua",
        position:"Education officer",
        image:joshua
    },
    
]
const NMember = [
    {
        imageName:"Tumutoneshe Diane",
        position:"Commissioner Ferwafa& Board Advisor",
        image:diane
    },
    {
        imageName:"Bizimana Augustin",
        position:"Founding Member & Board Advisor",
        image:augustin
    },
    {
        imageName:"Umulisa Muriel",
        position:"Founding Member& Advocate",
        image:milliel
    },
    {
        imageName:"Iradukunda Grace",
        position:"Founding Member & Advocate",
        image:grace
    },
    // {
    //     imageName:"Anderson Ndikumana",
    //     position:"member&Public relation",
    //     image:anderson
    // },
    
    {
        imageName:"Iradukunda Robert",
        position:"Conflict Resolution Committee &photographic Designer Officer",
        image:robert
    },

    {
        imageName:"Kaberuka Freddy",
        position:"Software Developer",
        image:freddy
    },
    {
        imageName:"Uwera Sonia",
        position:"Member& Advocate",
        image:murisa
    },
    
    
    
]

export {Members,NMember};