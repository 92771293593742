import React from "react";
import image1 from "../assets/1.jpg";
import image2 from "../assets/2.JPG";
import image3 from "../assets/3.JPG";
// import image4 from "../assets/sdgs81.jpg";
// import image5 from "../assets/health.jpeg";
import image6 from "../assets/educating.jpg";
import { Link } from "react-router-dom";
import Member from "./helper/member";

function Activities() {
  return (
    <div>
      <section id="about-sec">
        <div className="container">
          <div className="row text-left">

            <div className="act-box clearfix">
              <div className="col-md-6">
                <div className="image">
                  <img src={image3} alt="" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="act-pad">
                  <h4>SPONSOR AN EXTREMELY POOR CHILD EDUCATION</h4>
                  <p>
                    Within Supporting An extremely Poor Child to his right to
                    quality Education, You are playing a key role to achieving
                    all Sustainable Development goals(SDGs) and building&
                    promoting a critical Understanding among young people to a
                    lifelong learning opportunities for all{" "}
                  </p>
                  <p>
                    May you be blessed for setting a stone on Living Humanity.
                  </p>
                  <div className="price">
                    <Link to="/activity" className="btn1">
                      donate now
                    </Link>
                  </div>

                  <div className="clearfix"></div>
                </div>
              </div>
            </div>

            <div className="act-box clearfix">
              <div className="col-md-6 col-md-push-6">
                <div className="image">
                  <img src={image1} alt="" />
                </div>
              </div>
              <div className="col-md-6 col-md-pull-6">
                <div className="act-pad">
                  <h4>SPONSOR AN EXTREMELY POOR PATIENT IN HOSPITAL</h4>
                  <p>
                    Within Supporting A Vulnerable Patient with financial
                    hardships in Hospitals, You are saving a Life to An
                    extremely Poor Individual on the Sickbed including Children,
                    patients in elderly with no one to look after them..etc
                  </p>
                  <p>You are contributing to the #Healthforall Campaign.</p>
                  <p>
                    May you be blessed for setting a stone on Living Humanity.
                  </p>
                  <div className="price">
                    <Link to="/activity" className="btn1">
                      donate now
                    </Link>
                  </div>

                  <div className="clearfix"></div>
                </div>
              </div>
            </div>

            <div className="act-box clearfix">
              <div className="col-md-6">
                <div className="image">
                  <img src={image2} alt="" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="act-pad">
                  <h4>
                    SPONSOR AN EXTREMELY POOR INDIVIDUAL TO HAVING DECENT WORK
                  </h4>
                  <p>
                    Within Supporting the Womengirls Empowerment, You are
                    promoting sustained, inclusive and sustainable economic
                    growth, full and productive employment and decent work for
                    all as an achieving of gender equality and Empowerment all
                    women and girls, regardless their socio-economic
                    backgrounds.
                  </p>
                  <p>
                    May you be blessed for setting a Stone on Living Humanity
                    through Capacity Building.
                  </p>
                  <div className="price">
                    <Link to="/activity" className="btn1">
                      donate now
                    </Link>
                  </div>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>

            <div className="act-box clearfix">
              <div className="col-md-6 col-md-push-6">
                <div className="image">
                  <img src={image6} alt="" />
                </div>
              </div>
              <div className="col-md-6 col-md-pull-6">
                <div className="act-pad">
                  <h4>SPONSOR TO THE EDUCATING VALUES OF HUMANITY PROJECTS</h4>
                  <p>
                    With Supporting The Educating Value of Humanity Program, you
                    are becoming a founding member of the future Humanitarians
                    and promoting A Community Full of Values of Humanity which
                    is a key Foundation to achieving Sustainability in all
                    sectors of transformative development.
                  </p>
                  <p>
                    May you be blessed for becoming a milestone to living Value
                    of Humanity.
                  </p>
                  <div className="price">
                    <Link to="/activity" className="btn1">
                      donate now
                    </Link>
                  </div>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Member />
    </div>
  );
}

export default Activities;
