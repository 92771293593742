import React from 'react'
import image from '../assets/12.jpg'
import Member from './helper/member'
import { Link } from 'react-router-dom'
import image1 from '../assets/what we do.jpg';
import  image2 from '../assets/health.jpeg';
import image3 from '../assets/unemployment.jpeg';

function Project() {
  return (
    <div>
        <section id="inner-banner">
<div className="overlay">
<div className="container">
<div className="row"> 
<div className="col-sm-6"><h1>PROJECTS</h1></div>
<div className="col-sm-6">
  <h6 className="breadcrumb"><Link to="/">Home</Link> / Projects</h6></div>
</div>
</div>
</div>
</section>

<section id="pro-sec">
<div className="container">
<div className="row text-center pro-sec-wrap">

<div className="col-sm-6 col-md-4 clearfix top-off">
<div className="grid-image"><img src={image1} alt=""/></div>
<div className="post-content">
<h3>Gahanga: Education for Children</h3>
<hr/>
<p>we Youth Humanitarians in SDG4 Believes that Quality Education is the Child Right within Equity Gender, Right now as of 15 youth’s contributions, we support 20% of the Children we have with limited access to Education, 80% of them are still On the Pending List.  where now 60% Children in Education In Our Program are Girls.</p>
<Link to="/" title="" >Join Us</Link>
</div>

</div>
<div className="col-sm-6 col-md-4 clearfix top-off">
<div className="grid-image"><img src={image2} alt=""/></div>
<div className="post-content">
<h3>Kicukiro: Womengirls empowement</h3>
<hr/>
<p>We Youth Humanitarian in SDGs8, believe that raising the status of the Women through Education, literacy and trainings is the Women Empowerment leading them to financial access to sustainable economic growth</p>
<Link to="/" title="" >Join Us</Link>
</div>
</div>

<div className="col-sm-6 col-md-4 clearfix top-off">
<div className="grid-image"><img src={image3} alt=""/></div>
<div className="post-content">
<h3>CHUK HOSPITAL: UNIVERSAL HEALTH COVERAGE(UHC)</h3>
<hr/>
<p>We Youth Humanitarians in Health through Inezaforall group, we are collaborating with Social worker in Hospitals starting from CHUK to tackle financial hardships to health Services</p>
<Link to="/" title="" >Join Us</Link>
</div>
</div>

<div className="col-sm-6 col-md-4 clearfix top-off">
<div className="grid-image"><img src={image} alt=""/></div>
<div className="post-content">
<h3>EDUCATIVE VALUE OF HUMANITY</h3>
<hr/>
<p>We Youth Humanitarians in Health through Inezaforall group, we are collaborating with Social worker in Hospitals starting from CHUK to tackle financial hardships to health Services</p>
<Link to="/" title="" >Join Us</Link>
</div>
</div>


</div>
</div>
</section>
<Member/>
    </div>
  )
}

export default Project